
import { defineComponent }        from "vue";
import { BackButton, FloatLabel } from "@sharedComponents";

export default defineComponent( {
  name      : "KpiHeader",
  components: { FloatLabel, BackButton },
  props     : [ 'dateRange' ],
  emits     : [ 'update:dateRange' ],
} )
