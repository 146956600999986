import { HttpService } from "@services/base/http.service";
import { Shift }       from "@/model/Shift";

export interface ZipKpi {
    zip: string;
    shift_id: number;
    order_date: string;
    orders_count: number;
    shift: Shift;
}

export interface CustomerOrdersCount {
    id: number;
    business_name: string;
    orders_count: number;
}

export interface WorkingSupplier {
    id: number;
    business_name: string;
    type: number;
}

export interface CustomerInfo {
    id: number;
    business_name: string;
}

export interface ZoneInfo {
    id: number;
    name: string;
}

export interface PriorityOrder {
    id: number;
    pickup_datetime: Date;
    dropoff_datetime: Date;
    customer: CustomerInfo;
    zone: ZoneInfo;
}

export interface VehicleOrdersCount {
    id: number;
    name_it: string;
    orders_count: number;
}

export interface DropoffShapeOrdersCount {
    zone_name: string;
    shape1_orders_count: number;
    shape2_orders_count: number;
    shape3_orders_count: number;
    shape4_orders_count: number;
    shape5_orders_count: number;
    total: number;
}


export type SharedKpi = {
    pickup_zips: ZipKpi[];
    dropoff_zips: ZipKpi[];
    km_total: number;
    km_avg: number;
    vehicles: VehicleOrdersCount[];
    eta_driving_time_avg: number;
    real_driving_time_avg: number;
    pickup_waiting_avg: number;
    dropoff_waiting_avg: number;
    dropoff_shapes: DropoffShapeOrdersCount[];
    order_create_dropoff_time_avg: number;
}

export type ForAdminOnlyKpi = {
    customers_more_orders: CustomerOrdersCount[];
    working_suppliers: WorkingSupplier[];
    priority_orders: PriorityOrder[];

    cost_order_avg: number;
    est_cost_order_avg: number;

    price_order_avg: number;
    est_price_order_avg: number;
}

export type ForCustomerKpi = {
    price_order_avg: number;
}
export type ForSupplierKpi = {
    cost_order_avg: number;
}

export type CustomerKpi = SharedKpi & ForCustomerKpi;
export type SupplierKpi = SharedKpi & ForSupplierKpi;
export type Kpi = SharedKpi & ForAdminOnlyKpi & CustomerKpi & SupplierKpi;


export interface KpiParams {
    date_from_at: string;
    date_to_at: string;

    zone_id?: number;
    entity_id?: number;
    customer_id?: number;
}

class KpiService extends HttpService {
    readonly endPoint = `kpi`;

    admin( params: KpiParams ) {
        return this.get<Kpi>(
            `${ this.endPoint }/admin`,
            { params }
        )
    }

    customer( params: KpiParams ) {
        return this.get<Kpi>(
            `${ this.endPoint }/customer`,
            { params }
        )
    }

    supplier( params: KpiParams ) {
        return this.get<Kpi>(
            `${ this.endPoint }/supplier`,
            { params }
        )
    }
}

export const kpiService = new KpiService();
