import { computed, ref }  from "vue";
import { stringDuration } from "@/modules/orders/common";
import { useI18n }        from "vue-i18n";
import { Kpi }            from "@services/kpi.service";
import moment             from "moment";
import { useStore }       from "vuex";
import { key_store }      from "@/store";

export function useKpi() {
    const { t }     = useI18n();
    const isLoading = ref( false );


    const filters   = ref<any>( {} );
    const dateRange = ref<[ Date, Date ]>( [
        moment().subtract( 1, "month" ).startOf( 'month' ).toDate(),
        moment().subtract( 1, "month" ).endOf( 'month' ).toDate(),
    ] )

    function stringDateRange() {
        return dateRange.value?.map( ( d ) => moment( d as Date ).format( "YYYY-MM-DD" ) )
    }

    const kpi = ref<Kpi>()

    const kmTotal = computed( () => kpi.value?.km_total?.toLocaleString() + ' Km' );
    const kmAVG   = computed( () => kpi.value?.km_avg?.toLocaleString() + ' Km' );

    const priceOrderAVG    = computed( () => kpi.value?.price_order_avg?.toLocaleString() + " €" );
    const estPriceOrderAvg = computed( () => kpi.value?.est_price_order_avg?.toLocaleString() + " €" );

    const costOrderAVG    = computed( () => kpi.value?.cost_order_avg?.toLocaleString() + " €" );
    const estCostOrderAvg = computed( () => kpi.value?.est_cost_order_avg?.toLocaleString() + " €" );


    const etaDrivingAVG     = computed( () => stringDuration( kpi.value?.eta_driving_time_avg, t ) );
    const realDrivingAVG    = computed( () => stringDuration( kpi.value?.real_driving_time_avg, t ) );
    const pickupWaitingAVG  = computed( () => stringDuration( kpi.value?.pickup_waiting_avg, t ) );
    const dropoffWaitingAVG = computed( () => stringDuration( kpi.value?.dropoff_waiting_avg, t ) );

    const store = useStore( key_store );

    function saveParamsOnStore( date_from_at: string, date_to_at: string, filters?: any ) {
        store.commit( 'setKpiParams', {
            date_from_at,
            date_to_at,
            filters
        } );
    }

    function saveKpi( kpiValue: Kpi ) {
        store.commit( 'setKpi', kpiValue );
    }

    function reloadFromStore() {
        kpi.value = store.state.kpi;
    }

    function reloadParamsFromStore() {
        const { date_from_at, date_to_at } = store.state.kpiParams;
        dateRange.value                    = [
            moment( date_from_at ).toDate(),
            moment( date_to_at ).toDate(),
        ];

        filters.value = { ...(store.state.kpiParams.filters || {}) };
    }
    return {
        isLoading,

        filters,
        dateRange,
        stringDateRange,

        kpi,
        kmTotal,
        kmAVG,

        estPriceOrderAvg,
        priceOrderAVG,

        estCostOrderAvg,
        costOrderAVG,

        etaDrivingAVG,
        realDrivingAVG,
        pickupWaitingAVG,
        dropoffWaitingAVG,

        store,
        saveKpi,
        saveParamsOnStore,
        reloadFromStore,
        reloadParamsFromStore
    }
}
