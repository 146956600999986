
import { defineComponent }     from "vue";
import { Entity, UserType }    from "@/model/Entity";
import { SuppliersRoutesEnum } from "@/modules/suppliers/router";
import { TakersRoutesEnum }    from "@/modules/takers/router";

export default defineComponent( {
  props: {
    value    : {
      type: Array,
    },
    isLoading: {
      type    : Boolean,
      required: true,
    },
  },
  setup() {
    function getSupplierOrTakerRoute( data: Entity ) {
      return data.type === UserType.SUPPLIER
          ? {
            name  : SuppliersRoutesEnum.SUPPLIERS_DETAIL,
            params: {
              supplierId: data.id
            }
          }
          : {
            name  : TakersRoutesEnum.TAKERS_DETAIL,
            params: {
              takerId: data.id
            }
          };
    }

    return {
      getSupplierOrTakerRoute,
    };
  }
} )
