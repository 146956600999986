<template>
  <div>
    <AdminKPI v-if="isTMT"/>
    <CustomerKPI v-if="isCustomer"/>
    <SupplierKPI v-if="isSupplier"/>
  </div>
</template>

<script>
import AdminKPI from "@/modules/app/pages/Kpi/AdminKPI";
import CustomerKPI from "@/modules/app/pages/Kpi/CustomerKPI";
import SupplierKPI from "@/modules/app/pages/Kpi/SupplierKPI";

export default {
  components: {SupplierKPI, CustomerKPI, AdminKPI},
}
</script>
