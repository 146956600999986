import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = { class: "p-grid" }
const _hoisted_3 = { class: "p-col-12" }
const _hoisted_4 = { class: "p-grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KpiHeader = _resolveComponent("KpiHeader")!
  const _component_OverviewBox = _resolveComponent("OverviewBox")!
  const _component_ZonesTable = _resolveComponent("ZonesTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_KpiHeader, {
      dateRange: _ctx.dateRange,
      "onUpdate:dateRange": _cache[0] || (_cache[0] = ($event: any) => (_ctx.dateRange = $event)),
      onRefresh: _ctx.onRefresh,
      onDateSelect: _ctx.onDateSelect
    }, null, 8, ["dateRange", "onRefresh", "onDateSelect"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_OverviewBox, {
        isLoading: _ctx.isLoading,
        label: _ctx.$t('admin_kpi.eta_driving_avg'),
        value: _ctx.etaDrivingAVG,
        icon: "fas fa-clock",
        class: "p-col-12 p-md-6 p-lg-3",
        color: "blue"
      }, null, 8, ["isLoading", "label", "value"]),
      _createVNode(_component_OverviewBox, {
        isLoading: _ctx.isLoading,
        label: _ctx.$t('admin_kpi.real_driving_avg'),
        value: _ctx.realDrivingAVG,
        icon: "fas fa-clock",
        class: "p-col-12 p-md-6 p-lg-3",
        color: "green"
      }, null, 8, ["isLoading", "label", "value"]),
      _createVNode(_component_OverviewBox, {
        isLoading: _ctx.isLoading,
        label: _ctx.$t('admin_kpi.pickup_waiting_avg'),
        value: _ctx.pickupWaitingAVG,
        icon: "fas fa-clock",
        class: "p-col-12 p-md-6 p-lg-3",
        color: "orange"
      }, null, 8, ["isLoading", "label", "value"]),
      _createVNode(_component_OverviewBox, {
        isLoading: _ctx.isLoading,
        label: _ctx.$t('admin_kpi.dropoff_waiting_avg'),
        value: _ctx.dropoffWaitingAVG,
        icon: "fas fa-clock",
        class: "p-col-12 p-md-6 p-lg-3",
        color: "purple"
      }, null, 8, ["isLoading", "label", "value"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_ZonesTable, {
          isLoading: _ctx.isLoading,
          value: _ctx.kpi?.dropoff_shapes
        }, null, 8, ["isLoading", "value"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_OverviewBox, {
        isLoading: _ctx.isLoading,
        label: _ctx.$t('admin_kpi.km_total'),
        value: _ctx.kmTotal,
        icon: "fas fa-road",
        class: "p-col-12 p-md-6 p-lg-3",
        color: "blue"
      }, null, 8, ["isLoading", "label", "value"]),
      _createVNode(_component_OverviewBox, {
        isLoading: _ctx.isLoading,
        label: _ctx.$t('admin_kpi.km_avg'),
        value: _ctx.kmAVG,
        icon: "fas fa-road",
        class: "p-col-12 p-md-6 p-lg-3",
        color: "green"
      }, null, 8, ["isLoading", "label", "value"]),
      _createVNode(_component_OverviewBox, {
        isLoading: _ctx.isLoading,
        label: _ctx.$t('admin_kpi.cost_avg'),
        value: _ctx.costOrderAVG,
        icon: "fas fa-road",
        class: "p-col-12 p-md-6 p-lg-3",
        color: "purple"
      }, null, 8, ["isLoading", "label", "value"])
    ])
  ], 64))
}