import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card h100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DateFormatted = _resolveComponent("DateFormatted")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", null, _toDisplayString(_ctx.$t('admin_kpi.priority_orders_title')), 1),
    _createVNode(_component_DataTable, {
      loading: _ctx.isLoading,
      value: _ctx.value,
      scrollable: true,
      scrollHeight: "400px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          header: "ID",
          field: "id"
        }, {
          body: _withCtx(({data}) => [
            _createVNode(_component_router_link, {
              to: { name: _ctx.orderRouteName, params: { orderId: data.id } },
              class: "dark-link"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(data.id), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          header: "Ritiro",
          field: "pickup_datetime"
        }, {
          body: _withCtx(({data: {pickup_datetime}}) => [
            _createVNode(_component_DateFormatted, {
              date: pickup_datetime,
              format: "DD/MM/YYYY HH:mm"
            }, null, 8, ["date"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          header: "Consegna",
          field: "dropoff_datetime"
        }, {
          body: _withCtx(({data: {dropoff_datetime}}) => [
            _createVNode(_component_DateFormatted, {
              date: dropoff_datetime,
              format: "DD/MM/YYYY HH:mm"
            }, null, 8, ["date"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          header: "Cliente",
          field: "customer.business_name"
        }),
        _createVNode(_component_Column, {
          header: "Città",
          field: "zone.name"
        })
      ]),
      _: 1
    }, 8, ["loading", "value"])
  ]))
}