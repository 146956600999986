
import { defineComponent }     from "vue";
import { Customer }            from "@/model/Customer";
import { CustomersRoutesEnum } from "@/modules/customers/router";

export default  defineComponent({
  props: {
    value: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
    },
  },
  setup() {
    function getCustomerRoute( customer: Customer ) {
      return {
        name  : CustomersRoutesEnum.CUSTOMERS_DETAIL,
        params: {
          customerId: customer.id
        }
      }
    }

    return {
      getCustomerRoute
    }
  }
})
