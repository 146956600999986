
import { defineComponent }  from 'vue';
import DateFormatted        from "../../../../../nexor_vue_components/components/DateFormatted/DateFormatted.vue";
import { OrdersRoutesEnum } from "@/modules/orders/router";

export default defineComponent( {
  components: { DateFormatted },
  props     : {
    value    : {
      type: Array,
    },
    isLoading: {
      type: Boolean,
    },
  },
  computed  : {
    orderRouteName() {
      return OrdersRoutesEnum.ORDERS_DETAIL
    }
  }
} );
