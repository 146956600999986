import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card h100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_ShiftToText = _resolveComponent("ShiftToText")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _directive_date_format = _resolveDirective("date-format")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", null, _toDisplayString(_ctx.title), 1),
    _createVNode(_component_DataTable, {
      loading: _ctx.isLoading,
      scrollable: true,
      scrollHeight: "400px",
      value: _ctx.value
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          header: "Codice Postale",
          field: "zip",
          style: {"width":"5rem"}
        }),
        _createVNode(_component_Column, {
          header: "Giorno",
          field: "order_date"
        }, {
          body: _withCtx(({data: {order_date}}) => [
            _withDirectives(_createElementVNode("span", null, [
              _createTextVNode(_toDisplayString(order_date), 1)
            ], 512), [
              [_directive_date_format]
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          header: "Fascia",
          style: {"min-width":"10rem"}
        }, {
          body: _withCtx(({data: {shift}}) => [
            _createVNode(_component_ShiftToText, { shift: shift }, null, 8, ["shift"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          header: "N° Ordini",
          headerClass: "column-header-right",
          class: "p-text-right",
          field: "orders_count"
        })
      ]),
      _: 1
    }, 8, ["loading", "value"])
  ]))
}