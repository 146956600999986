
import { computed, defineComponent, onMounted } from "vue";
import OverviewBox                              from "@/modules/app/pages/Kpi/OverviewBox.vue";
import { kpiService }                           from "@services/kpi.service";
import { useMessage }                           from "@plugins/Messages-plugin";
import ZipTable                                 from "@/modules/app/pages/Kpi/ZipTable.vue";
import OnDutyTable                              from "@/modules/app/pages/Kpi/OnDutyTable.vue";
import CustomersTable                           from "@/modules/app/pages/Kpi/CustomersTable.vue";
import PriorityOrdersTable                      from "@/modules/app/pages/Kpi/PriorityOrdersTable.vue";
import ZonesTable                               from "@/modules/app/pages/Kpi/ZonesTable.vue";
import { useKpi }                               from "@/modules/app/pages/Kpi/kpi";
import VehiclesKPI                              from "@/modules/app/pages/Kpi/vehicles/VehiclesKPI.vue";
import KpiHeader                                from "@/modules/app/pages/Kpi/header/KpiHeader.vue";
import { FloatLabel }                           from "@sharedComponents";

import { CustomerAutocomplete, SupplierAutocomplete, TakerAutocomplete, ZoneAutocomplete } from "@/components";

export default defineComponent( {
  components: {
    SupplierAutocomplete,
    TakerAutocomplete,
    CustomerAutocomplete,
    FloatLabel,
    ZoneAutocomplete,
    KpiHeader,
    VehiclesKPI,
    ZonesTable,
    PriorityOrdersTable,
    CustomersTable,
    OnDutyTable,
    ZipTable,
    OverviewBox,
  },

  setup() {
    const { errorMessage } = useMessage();

    const {
            isLoading,

            dateRange,
            stringDateRange,

            kpi,
            kmTotal,
            kmAVG,

            estPriceOrderAvg,
            priceOrderAVG,

            estCostOrderAvg,
            costOrderAVG,

            etaDrivingAVG,
            realDrivingAVG,
            pickupWaitingAVG,
            dropoffWaitingAVG,

            store,
            saveKpi,
            saveParamsOnStore,
            reloadFromStore,
            reloadParamsFromStore,

            filters
          } = useKpi();

    const priceValue = computed( () => `${ estPriceOrderAvg.value } / ${ priceOrderAVG.value }` );
    const costValue  = computed( () => `${ estCostOrderAvg.value } / ${ costOrderAVG.value }` );

    async function loadAdminKpi() {
      try {
        isLoading.value = true;

        const [ date_from_at, date_to_at ]        = stringDateRange();
        const { supplier, taker, zone, customer } = filters.value;

        kpi.value = await kpiService.admin( {
          date_from_at,
          date_to_at,
          entity_id  : supplier?.id || taker?.id,
          customer_id: customer?.id,
          zone_id    : zone?.id,
        } );

        saveParams();
        saveKpi( kpi.value );

      } catch (error) {
        console.error( error );
        errorMessage( "Si è verificato un errore durante il caricamento dei dati" );
      } finally {
        isLoading.value = false;
      }
    }

    function onDateSelect() {
      if (dateRange.value?.every( x => !!x )) {
        loadAdminKpi()
      }
    }

    function onRefresh() {
      loadAdminKpi()
    }


    function onFiltersChange( changedField: string ) {
      if (changedField === "taker") {
        filters.value.supplier = null;
      } else if (changedField === "supplier") {
        filters.value.taker = null;
      }

      loadAdminKpi();
    }

    function saveParams() {
      const [ date_from_at, date_to_at ] = stringDateRange();

      saveParamsOnStore(
          date_from_at, date_to_at, { ...filters.value }
      );
    }

    onMounted( () => {
      if (store.state.kpi) {
        reloadFromStore();
        reloadParamsFromStore();
      } else {
        loadAdminKpi();
      }
    } );


    return {
      onRefresh,
      onDateSelect,
      isLoading,
      dateRange,
      kpi,

      kmTotal,
      kmAVG,

      priceValue,
      costValue,

      etaDrivingAVG,
      realDrivingAVG,
      pickupWaitingAVG,
      dropoffWaitingAVG,

      filters,
      onFiltersChange,
    }
  }
} )
