
import { defineComponent } from "vue";
import { dateFormat }      from "@/directives";
import { ShiftToText }     from "@/components";

export default defineComponent( {
  components: {
    ShiftToText
  },

  directives: {
    dateFormat
  },

  props: {
    title: {
      type: String,
      required: true
    },
    value    : {
      type    : Array,
    },
    isLoading: {
      type   : Boolean,
      default: false
    }
  },
} )
