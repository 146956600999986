import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    loading: _ctx.isLoading,
    scrollable: true,
    value: _ctx.value,
    scrollHeight: "400px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Column, {
        field: "id",
        header: "ID"
      }),
      _createVNode(_component_Column, {
        field: "name_it",
        header: "Nome"
      }),
      _createVNode(_component_Column, {
        field: "orders_count",
        header: "N° Ordini"
      })
    ]),
    _: 1
  }, 8, ["loading", "value"]))
}