import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = { class: "p-grid" }
const _hoisted_3 = { class: "p-col-12 p-lg-6" }
const _hoisted_4 = { class: "p-col-12 p-lg-6" }
const _hoisted_5 = { class: "p-grid" }
const _hoisted_6 = { class: "p-grid" }
const _hoisted_7 = { class: "p-col-12 p-lg-6" }
const _hoisted_8 = { class: "p-col-12 p-lg-6" }
const _hoisted_9 = { class: "p-grid" }
const _hoisted_10 = { class: "p-col-12 p-lg-6" }
const _hoisted_11 = { class: "p-col-12 p-lg-6" }
const _hoisted_12 = { class: "p-grid" }
const _hoisted_13 = { class: "p-col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ZoneAutocomplete = _resolveComponent("ZoneAutocomplete")!
  const _component_FloatLabel = _resolveComponent("FloatLabel")!
  const _component_CustomerAutocomplete = _resolveComponent("CustomerAutocomplete")!
  const _component_TakerAutocomplete = _resolveComponent("TakerAutocomplete")!
  const _component_SupplierAutocomplete = _resolveComponent("SupplierAutocomplete")!
  const _component_KpiHeader = _resolveComponent("KpiHeader")!
  const _component_OverviewBox = _resolveComponent("OverviewBox")!
  const _component_PriorityOrdersTable = _resolveComponent("PriorityOrdersTable")!
  const _component_OnDutyTable = _resolveComponent("OnDutyTable")!
  const _component_ZipTable = _resolveComponent("ZipTable")!
  const _component_CustomersTable = _resolveComponent("CustomersTable")!
  const _component_VehiclesKPI = _resolveComponent("VehiclesKPI")!
  const _component_ZonesTable = _resolveComponent("ZonesTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_KpiHeader, {
      dateRange: _ctx.dateRange,
      "onUpdate:dateRange": _cache[6] || (_cache[6] = ($event: any) => (_ctx.dateRange = $event)),
      onRefresh: _ctx.onRefresh,
      onDateSelect: _ctx.onDateSelect
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FloatLabel, {
          class: "p-mr-3",
          label: "Città"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ZoneAutocomplete, {
              modelValue: _ctx.filters.zone,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.filters.zone = $event)),
              onResultSelected: _ctx.onFiltersChange
            }, null, 8, ["modelValue", "onResultSelected"])
          ]),
          _: 1
        }),
        _createVNode(_component_FloatLabel, {
          class: "p-mr-3",
          label: "Cliente"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_CustomerAutocomplete, {
              modelValue: _ctx.filters.customer,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.filters.customer = $event)),
              onResultSelected: _ctx.onFiltersChange
            }, null, 8, ["modelValue", "onResultSelected"])
          ]),
          _: 1
        }),
        _createVNode(_component_FloatLabel, {
          class: "p-mr-3",
          label: "Taker"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_TakerAutocomplete, {
              modelValue: _ctx.filters.taker,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.filters.taker = $event)),
              onResultSelected: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onFiltersChange('taker')))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_FloatLabel, {
          class: "p-mr-3",
          label: "Fornitore"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_SupplierAutocomplete, {
              modelValue: _ctx.filters.supplier,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.filters.supplier = $event)),
              onResultSelected: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onFiltersChange('supplier')))
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["dateRange", "onRefresh", "onDateSelect"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_OverviewBox, {
        isLoading: _ctx.isLoading,
        label: _ctx.$t('admin_kpi.km_total'),
        value: _ctx.kmTotal,
        icon: "fas fa-road",
        class: "p-col-12 p-md-6 p-lg-3",
        color: "blue"
      }, null, 8, ["isLoading", "label", "value"]),
      _createVNode(_component_OverviewBox, {
        isLoading: _ctx.isLoading,
        label: _ctx.$t('admin_kpi.km_avg'),
        value: _ctx.kmAVG,
        icon: "fas fa-road",
        class: "p-col-12 p-md-6 p-lg-3",
        color: "green"
      }, null, 8, ["isLoading", "label", "value"]),
      _createVNode(_component_OverviewBox, {
        isLoading: _ctx.isLoading,
        label: _ctx.$t('admin_kpi.price_avg'),
        value: _ctx.priceValue,
        icon: "fas fa-euro-sign",
        class: "p-col-12 p-md-6 p-lg-3",
        color: "orange"
      }, null, 8, ["isLoading", "label", "value"]),
      _createVNode(_component_OverviewBox, {
        isLoading: _ctx.isLoading,
        label: _ctx.$t('admin_kpi.cost_avg'),
        value: _ctx.costValue,
        icon: "fas fa-euro-sign",
        class: "p-col-12 p-md-6 p-lg-3",
        color: "purple"
      }, null, 8, ["isLoading", "label", "value"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_PriorityOrdersTable, {
          isLoading: _ctx.isLoading,
          value: _ctx.kpi?.priority_orders
        }, null, 8, ["isLoading", "value"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_OnDutyTable, {
          isLoading: _ctx.isLoading,
          value: _ctx.kpi?.working_suppliers
        }, null, 8, ["isLoading", "value"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_OverviewBox, {
        isLoading: _ctx.isLoading,
        label: _ctx.$t('admin_kpi.eta_driving_avg'),
        value: _ctx.etaDrivingAVG,
        icon: "fas fa-clock",
        class: "p-col-12 p-md-6 p-lg-3",
        color: "blue"
      }, null, 8, ["isLoading", "label", "value"]),
      _createVNode(_component_OverviewBox, {
        isLoading: _ctx.isLoading,
        label: _ctx.$t('admin_kpi.real_driving_avg'),
        value: _ctx.realDrivingAVG,
        icon: "fas fa-clock",
        class: "p-col-12 p-md-6 p-lg-3",
        color: "green"
      }, null, 8, ["isLoading", "label", "value"]),
      _createVNode(_component_OverviewBox, {
        isLoading: _ctx.isLoading,
        label: _ctx.$t('admin_kpi.pickup_waiting_avg'),
        value: _ctx.pickupWaitingAVG,
        icon: "fas fa-clock",
        class: "p-col-12 p-md-6 p-lg-3",
        color: "orange"
      }, null, 8, ["isLoading", "label", "value"]),
      _createVNode(_component_OverviewBox, {
        isLoading: _ctx.isLoading,
        label: _ctx.$t('admin_kpi.dropoff_waiting_avg'),
        value: _ctx.dropoffWaitingAVG,
        icon: "fas fa-clock",
        class: "p-col-12 p-md-6 p-lg-3",
        color: "purple"
      }, null, 8, ["isLoading", "label", "value"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_ZipTable, {
          isLoading: _ctx.isLoading,
          value: _ctx.kpi?.pickup_zips,
          title: _ctx.$t('admin_kpi.pickup_zips_title')
        }, null, 8, ["isLoading", "value", "title"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_ZipTable, {
          isLoading: _ctx.isLoading,
          value: _ctx.kpi?.dropoff_zips,
          title: _ctx.$t('admin_kpi.dropoff_zips_title')
        }, null, 8, ["isLoading", "value", "title"])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_CustomersTable, {
          isLoading: _ctx.isLoading,
          value: _ctx.kpi?.customers_more_orders
        }, null, 8, ["isLoading", "value"])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(_component_VehiclesKPI, {
          isLoading: _ctx.isLoading,
          value: _ctx.kpi?.vehicles
        }, null, 8, ["isLoading", "value"])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_ZonesTable, {
          isLoading: _ctx.isLoading,
          value: _ctx.kpi?.dropoff_shapes
        }, null, 8, ["isLoading", "value"])
      ])
    ])
  ], 64))
}