
import { defineComponent, onMounted } from "vue";
import { kpiService }                 from "@services/kpi.service";
import { useMessage }                 from "@plugins/Messages-plugin";
import OverviewBox                    from "@/modules/app/pages/Kpi/OverviewBox.vue";
import ZonesTable                     from "@/modules/app/pages/Kpi/ZonesTable.vue";
import { useKpi }                     from "./kpi";
import KpiHeader                      from "@/modules/app/pages/Kpi/header/KpiHeader.vue";

export default defineComponent( {
  components: {
    KpiHeader,
    ZonesTable,
    OverviewBox,
  },

  setup() {
    const { errorMessage } = useMessage();

    const {
            isLoading,

            dateRange,
            stringDateRange,

            kpi,
            kmTotal,
            kmAVG,
            costOrderAVG,
            etaDrivingAVG,
            realDrivingAVG,
            pickupWaitingAVG,
            dropoffWaitingAVG,

            store,
            saveKpi,
            saveParamsOnStore,
            reloadFromStore,
            reloadParamsFromStore
          } = useKpi();


    async function loadSupplierKpi() {
      try {
        isLoading.value = true;

        const [ date_from_at, date_to_at ] = stringDateRange();

        kpi.value = await kpiService.supplier( { date_from_at, date_to_at } );

        saveParamsOnStore( date_from_at, date_to_at );
        saveKpi( kpi.value );

      } catch (error) {
        console.error( error );
        errorMessage( "Si è verificato un errore durante il caricamento dei dati" );
      } finally {
        isLoading.value = false;
      }
    }

    function onDateSelect() {
      if (dateRange.value?.every( x => !!x )) {
        loadSupplierKpi()
      }
    }

    function onRefresh() {
      loadSupplierKpi()
    }

    onMounted( () => {
      if (store.state.kpi) {
        reloadFromStore()
        reloadParamsFromStore()
      } else {
        loadSupplierKpi();
      }
    } );

    return {
      onRefresh,
      onDateSelect,
      isLoading,
      dateRange,
      kpi,

      kmTotal,
      kmAVG,
      costOrderAVG,

      etaDrivingAVG,
      realDrivingAVG,
      pickupWaitingAVG,
      dropoffWaitingAVG,
    }
  }
} )
