
import { defineComponent } from 'vue';
import { ZonesRoutesEnum } from "@/modules/zones/router";

export default defineComponent( {
  props   : {
    value    : {
      type: Array,
    },
    isLoading: {
      type: Boolean,
    },
  },
  computed: {
    zoneRouteName() {
      return ZonesRoutesEnum.ZONE_DETAIL;
    }
  },
} );
