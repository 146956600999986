import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card h100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VehiclesTable = _resolveComponent("VehiclesTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("h5", null, _toDisplayString(_ctx.$t('admin_kpi.vehicles_title')), 1)
    ]),
    (_ctx.layout === 'table')
      ? (_openBlock(), _createBlock(_component_VehiclesTable, {
          key: 0,
          isLoading: _ctx.isLoading,
          value: _ctx.value
        }, null, 8, ["isLoading", "value"]))
      : _createCommentVNode("", true)
  ]))
}