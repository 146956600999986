import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card h100" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", null, _toDisplayString(_ctx.$t('admin_kpi.suppliers_takers_on_duty')), 1),
    _createVNode(_component_DataTable, {
      loading: _ctx.isLoading,
      value: _ctx.value,
      scrollable: true,
      scrollHeight: "400px"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "id",
          header: "ID",
          style: {"max-width":"5rem"}
        }, {
          body: _withCtx(({data}) => [
            _createVNode(_component_router_link, {
              class: "dark-link",
              to: _ctx.getSupplierOrTakerRoute(data)
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(data.id), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, { header: "Nome" }, {
          body: _withCtx(({data}) => [
            _createElementVNode("div", null, [
              _createElementVNode("strong", null, _toDisplayString(data.business_name), 1),
              _createElementVNode("div", null, [
                _createElementVNode("span", null, _toDisplayString(data.name), 1),
                _createElementVNode("span", null, _toDisplayString(data.surname), 1)
              ])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_Column, {
          header: "Tipo",
          field: "type"
        }, {
          body: _withCtx(({data: {type}}) => [
            (type === 10)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('admin_kpi.supplier_type')), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('admin_kpi.taker_type')), 1))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["loading", "value"])
  ]))
}