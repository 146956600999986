
import { defineComponent, ref } from "vue";
import VehiclesTable            from "@/modules/app/pages/Kpi/vehicles/VehiclesTable.vue";

export default defineComponent( {
  name      : "VehiclesKPI",
  components: { VehiclesTable },
  props     : {
    value    : {
      type: Array,
    },
    isLoading: {
      type: Boolean,
    },
  },
  setup() {
    const layout = ref<'table' | 'chart'>( 'table' )

    function changeLayout( type: 'table' | 'chart' ) {
      layout.value = type
    }

    return {
      layout,
      changeLayout
    };
  }
} )
