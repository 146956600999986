<template>
  <div>
    <div class="p-grid overview-box h100"
         :class="`overview-box-${$attrs.color}`">

      <div class="loading_spinner" v-if="$attrs.isLoading">
        <ProgressSpinner
            strokeWidth="5"
            animationDuration=".5s"
            style="width:50px;height:50px"
        />
      </div>

      <div class="overview-box-title">
        <i :class="`${$attrs.icon || 'pi pi-inbox'}`"/>
        <span>{{ $attrs.isLoading ? '...' : $attrs.label }}</span>
      </div>
      <div class="overview-box-count p-mt-auto">
        {{ $attrs.value }}
      </div>
    </div>
  </div>

</template>

<style lang="scss">
.overview-box {
  position: relative;
  padding: 15px;
  color: #ffffff;
  min-height: 100px;
  border-radius: 4px;
  margin: 0 !important;

  .loading_spinner {
    position: absolute;
    top: 0;
    left: 0;
    backdrop-filter: blur(5px);
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
  }

  .overview-box-title {
    display: flex;
    align-items: center;

    font-weight: bold;
    width: 100%;

    i {
      vertical-align: middle;
      font-size: 20px;
      margin-right: 1rem;
    }

    span {
      margin-left: .5em;
      vertical-align: middle;
    }
  }

  .overview-box-count {
    color: #ffffff;
    font-size: 24px;
    width: 100%;
    display: block;
    padding: 5px 0;
  }

  &.overview-box-blue {
    background-color: #007bff;
    border: solid 1px #007bff;
    color: #ffffff;
  }

  &.overview-box-green {
    background-color: #28a745;
    border: solid 1px #28a745;
    color: #ffffff;
  }

  &.overview-box-orange {
    background-color: #fd7e14;
    border: solid 1px #fd7e14;
    color: #ffffff;
  }

  &.overview-box-purple {
    background-color: #6f42c1;
    border: solid 1px #6f42c1;
    color: #ffffff
  }
}
</style>
